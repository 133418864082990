import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="remove-element"
export default class extends Controller {
  static values = {
    id: String,
    delay: { type: Number, default: 0 }
  }

  remove() {
    setTimeout(() => {
      document.getElementById(this.idValue).remove();
    }, this.delayValue);
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-toast-on-click"
export default class extends Controller {
  static values = {
    message: String,
    type: String
  }

  connect() {
    this.element.addEventListener("click", () => {
      const toast = document.createElement("div")
      toast.className = `alert alert-${this.typeValue}`
      toast.innerHTML = `<span>${this.messageValue}</span>`

      document.getElementById('toasts').append(toast)

      setTimeout(() => {
        toast.style.animation = "toast-hide 0.25s ease-in";
        toast.addEventListener("animationend", () => toast.remove())
        toast.addEventListener("webkitAnimationEnd", () => toast.remove())
      }, 2000)
    })
  }
}

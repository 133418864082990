import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="content-search"
export default class extends Controller {
  connect() {
    this.element.querySelectorAll(':scope > div').forEach((divElement) => {
      if (!divElement.querySelector('mark')) {
        divElement.remove()
      }
    })
  }
}

import { Controller } from "@hotwired/stimulus"
import Pagy from "pagy.mjs"

// Connects to data-controller="pagy-initializer"
export default class extends Controller {
  connect() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((_mutation) => {
        if (this.element.querySelectorAll('a').length > 1) {
          this.element.querySelector('a:first-of-type').innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
              <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          `

          this.element.querySelector('a:last-of-type').innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
          `

          this.element.querySelectorAll('.gap').forEach((element) => {
            element.innerHTML = `
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
              </svg>
            `
          })

          observer.disconnect()
        }
      })
    })

    observer.observe(this.element, { childList: true, subtree: true })

    Pagy.init(this.element)
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="daisy-ui--dropdown"
export default class extends Controller {
  connect() {
    this.element.querySelectorAll("li").forEach(item => {
      item.addEventListener("click", () => {
        this.element.removeAttribute("open")
      })
    })
  }
}

import { Controller } from "@hotwired/stimulus"
import { submitForm } from "../helpers/form_helpers"

const AUTOSAVE_INTERVAL = 3000

// Connects to data-controller="auto-save"
export default class extends Controller {
  static targets = ["savingStatus", "spinner", "tick"]
  static values = {
    savedText: String,
    savingText: String
  }

  #timer

  disconnect() {
    this.submit()
  }

  async submit() {
    if (this.#dirty) {
      await this.#save()
    }
  }

  change(event) {
    if (event.target.form === this.element && !this.#dirty) {
      this.#scheduleSave()
      this.#toggleAppearance(true)
    }
  }

  async #save() {
    this.#resetTimer()
    await submitForm(this.element)
    this.#toggleAppearance()
  }

  #toggleAppearance(saving = false) {
    this.savingStatusTarget.classList.remove("hidden")

    if (saving) {
      this.savingStatusTarget.setAttribute("data-tip", this.savingTextValue)
      this.spinnerTarget.classList.remove("hidden")
      this.tickTarget.classList.add("hidden")
    } else {
      this.savingStatusTarget.setAttribute("data-tip", this.savedTextValue)
      this.spinnerTarget.classList.add("hidden")
      this.tickTarget.classList.remove("hidden")
    }
  }

  #scheduleSave() {
    this.#timer = setTimeout(() => this.#save(), AUTOSAVE_INTERVAL)
  }

  #resetTimer() {
    clearTimeout(this.#timer)
    this.#timer = null
  }

  get #dirty() {
    return !!this.#timer
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="font-size"
export default class extends Controller {
  static targets = ["textarea"]

  connect() {
    this.loadFontSizeAndLineHeight()
  }

  reset() {
    this.textareaTarget.style.fontSize = "1.125rem"
    this.textareaTarget.style.lineHeight = "1.75rem"

    this.saveFontSizeAndLineHeight()
  }

  increase() {
    this.changeFontSizeAndLineHeight(0.125)
  }

  decrease() {
    this.changeFontSizeAndLineHeight(-0.125)
  }

  changeFontSizeAndLineHeight(delta) {
    const currentFontSize = parseFloat(this.textareaTarget.style.fontSize)
    const currentLineHeight = parseFloat(this.textareaTarget.style.lineHeight)

    this.textareaTarget.style.fontSize = `${currentFontSize + delta}rem`
    this.textareaTarget.style.lineHeight = `${currentLineHeight + delta}rem`

    this.saveFontSizeAndLineHeight()
  }

  loadFontSizeAndLineHeight() {
    const fontSize = localStorage.getItem('fontSize') || '1.125rem'
    const lineHeight = localStorage.getItem('lineHeight') || '1.75rem'

    this.textareaTarget.style.fontSize = fontSize
    this.textareaTarget.style.lineHeight = lineHeight
  }

  saveFontSizeAndLineHeight() {
    localStorage.setItem('fontSize', this.textareaTarget.style.fontSize)
    localStorage.setItem('lineHeight', this.textareaTarget.style.lineHeight)
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="copy-to-clipboard"
export default class extends Controller {
  static values = {
    content: String
  }

  connect() {
    this.element.addEventListener('click', this.copy.bind(this))
  }

  async copy() {
    try {
      await navigator.clipboard.writeText(this.contentValue)
    } catch { }
  }
}
